<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>親イベント一覧</template>
              <template v-slot:body>
                <div class="scrollX">
                  <Table
                    :dataTable="dataTable"
                    :labels="labels"
                    :hasStatusClass="false"
                    hasButtonColumns
                  ></Table>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog :listLength="parentEventCount" :modulePath="modulePath"></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter v-if="$permission.isGmo()">
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: 'SettingEventParentRegister' }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/components/Table.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import nav from '@/mixins/nav/setting';
import status from '@/mixins/plugin/status';
import event from '@/mixins/module/event';
import { formatDate, formatLocalString } from '@/helpers/formatData';
import { RoleConstants } from '@/constants/role';

export default {
  name: 'SettingEvent',
  data: function() {
    return {
      pageName: '設定',
      modulePath: 'event/getParentEventList'
    };
  },
  mixins: [nav, status, event],
  components: {
    Table,
    PaginationLog,
  },
  computed: {
    ...mapGetters({
      parentEventList: 'event/parentEventList',
      parentEventCount: 'event/parentEventCount',
      userChildRoles: 'auth/userChildRoles',
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      isUseOmise: 'auth/isUseOmise',
      isUseVerificationIdentity: 'auth/isUseVerificationIdentity',
      existVCN: 'event/existVCN',
      existIdentity: 'event/existIdentity',
      existOmise: 'event/existOmise',
      existSms: 'event/existSms',
      isUseSms: 'auth/isUseSms',
      existNec: 'event/existNec',
    }),
    dataTable() {
      return this.parentEventList.map(item => {
        const linkButtonColumns = [
          !this.isClientChild ? { content: '編集', class: 'btn-bd-main', routeName: 'SettingEventParentEdit', params: { subdomain: item.subdomain } } : null,
          { content: '親イベントページ', class: 'btn-white', href: item?.eventUrl, icon: 'aikon-external' },
          (!!item?.shopUrl && !this.isClientChild && this.hasShopRole)? { content: '加盟店・グループ管理画面', class: 'btn-white', href: item?.shopUrl, icon: 'aikon-external' } : null,
        ].filter(Boolean);
        return {
          name: this.parentNameContent(item),
          clients: this.clientContent(item.clients),
          shops: formatLocalString(item.aggregate?.shops),
          date: formatDate(item.createDate),
          vcn: { class: item?.useVcn ? 'd-block squareLink-text w-100 icon-vcn aikon aikon-check': 'd-block' },
          app: { class: item?.omiseLinked ? 'd-block w-100 squareLink-text ml-20 aikon aikon-check' : 'd-block' },
          identity: { class: item?.identityVerification ? 'd-block w-100 squareLink-text ml-20 aikon aikon-check' : 'd-block' },
          sms: { class: item?.useSms ? 'd-block w-100 squareLink-text icon-sms aikon aikon-check' : 'd-block' },
          otherApp: { class: item?.useLinkageNecFlag ? 'd-block w-100 squareLink-text ml-20 aikon aikon-check' : 'd-block' },
          linkButtonColumns
        };
      });
    },
    labels() {
      const data = {
        name: '親イベント名',
        clients: '事務局名／企業名',
        shops: '店舗数',
        date: '作成日',
        vcn: 'VCN',
        app: 'Omi-app',
        identity: '本人確認',
        sms: 'SMS',
        otherApp: 'Oth-app',
      }
      if (this.isClientChild) {
        delete data.vcn;
        delete data.app;
        delete data.identity;
        delete data.sms;
        delete data.otherApp;
      }
      if (!this.isUseSms || !this.existSms) delete data.sms;
      if (!this.existVCN) delete data.vcn;
      if (!this.isUseOmise || !this.existOmise) delete data.app;
      if (!this.isUseVerificationIdentity || !this.existIdentity) delete data.identity;
      if (!this.existNec) delete data.otherApp;
      return data;
    }
  },
  async created () {
    await this.$store.dispatch('auth/getUserInfor');
    if (this.userChildRoles && !this.userChildRoles.includes(RoleConstants.ROLE_EVENTS_OFFICE)) {
      this.$router.push({ name: '404' });
    }
    this.$store.commit('event/RESET_PARENT_EVENT_DETAIL');
    this.$store.commit('event/RESET_CHILD_EVENT_LIST');
  },
};
</script>
